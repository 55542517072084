export type IPrcUserTypes = 'Administrator' | 'User' | 'Read';

export enum PrcUserTypesEnum {
  Administrator = 'Administrator',
  User = 'User',
  Read = 'Read'
}

export type INpsUserTypes = 'PricingManager' | 'Legal' | 'PrcieCoordinator' | 'NpsAdministrator';

export enum NpsUserTypesEnum {
  PricingManager = 'PricingManager',
  Legal = 'Legal',
  PrcieCoordinator = 'PrcieCoordinator',
  NpsAdministrator = 'NpsAdministrator'
}

export type IAllUserTypes = IPrcUserTypes | INpsUserTypes;
